.flex-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  align-items: center;
  overflow-x: hidden;
  padding-left: 10px;
}

.flex-container-fex {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 20px;
  max-height: calc(100vh - 300px);
  overflow-y: auto;
  align-items: flex-start;
  overflow-x: hidden;
  padding-left: 10px;
  margin-bottom: 100px;
}

.flex-item {
  margin-bottom: 40px;
  flex: 1 0 calc(80% - 20px);
  max-width: calc(85% - 20px);
}

.input-group {
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}


.edit-button,
.save-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffc107;
  color: #000;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.cancel-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: red;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-right: 5px;
}

.input-group {
  margin-bottom: 10px;
}
.container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-container {
  padding: 20px;
  border-radius: 10px;
  width: 80%;
  max-width: 400px;
}

.form-container h2 {
  text-align: center;
}

.input-group {
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.input-group label {
  flex: 0 0 auto;
  width: 100px;
  /* Số liệu tùy chỉnh để điều chỉnh độ rộng của label */
}

.input-group input {
  flex: 1;
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

.error-message {
  text-align: center;
  color: red;
  margin-top: 10px;
}

.submit-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #28a745;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.submit-button-edit {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #ffc107;
  color: #000;
  border: none;
  cursor: pointer;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
}
.input-group .input-field {
  margin-bottom: 10px;
  flex: 1 0 33%;
  max-width: 33%;
}

.input-group .input-field input {
  padding: 8px;
  border-radius: 4px;
  border: 1px solid #ccc;
  margin-right: 10px;
  width: 80%;
}

.input-group .delete-button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #dc3545;
  color: #fff;
  border: none;
  cursor: pointer;
  width: auto;
}

@media only screen and (min-width: 768px) {
  .input-group .input-field {
    flex: 1 0 1 auto;
    max-width: none;
  }
}